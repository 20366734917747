/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  html {
    font-family: 'Inter', system-ui, sans-serif;
    height: 100%;
  }
  body,
  main,
  #root {
    height: 100%;
  }
  :not(:root):fullscreen::backdrop {
    background: initial;
  }
}

@layer components {
  .main-bg::before {
    position: fixed;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    background: #47bae8;
    background-image: linear-gradient(62deg, #47bae8 0%, #8073b0 100%);
    background-size: contain;
    z-index: -1;
  }
  .phoneField-flagDropdown {
    background-color: initial !important;
    border: none !important;
    padding-right: 0px;
  }

  .phoneField-flagDropdown .selected-flag.open {
    background-color: initial !important;
  }

  .phoneField-flagDropdown .country-list {
    box-shadow: none !important;
    border: 1px solid #cacaca;
    border-radius: 6px;
    width: calc(100vw - 31px) !important;
  }

  .kiosk-bg-gray {
    background: linear-gradient(58.72deg, #485563 7.71%, #29323c 90.58%);
  }

  /* Ticket css */

  .notchedDiv {
    display: flex;
    flex-flow: row nowrap;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    background: transparent;
    min-height: 28px;
    overflow: hidden;
    /* z-index: -1; */
  }

  .notchedDivLeft::before {
    @apply absolute;
    top: 9px;
    left: 0;
    margin-left: -14px;
    content: '';
    height: 11px;
    width: 21px;
    border-radius: 16px;
    z-index: -1;
    box-shadow: 0 0 0 25px white;
  }

  .notchedDivRight::after {
    @apply absolute;
    top: 9px;
    right: 0;
    margin-left: -14px;
    content: '';
    height: 11px;
    width: 21px;
    border-radius: 16px;
    z-index: -1;
    box-shadow: 0 0 0 25px white;
  }

  .separatorContainer {
    width: 100%;
    background: white;
    padding: 0 8px;
    display: flex;
  }

  .separator {
    flex-basis: 100%;
    align-self: center;
    padding: 0;
    border-top: 2px dashed rgba(46, 56, 66, 0.4);
  }

  .boxRounded {
    background: white;
    color: #58606c;
  }

  .boxRoundedTop {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  .btn-primary {
    @apply bg-primary-600 text-white border-primary-600 hover:bg-primary-800 active:bg-primary-800 active:border-primary-800;
  }
  .btn-secondary {
    @apply bg-white text-gray-700 border-gray-300 hover:bg-gray-50;
  }
  .btn-error {
    @apply bg-red-600  text-white  hover:bg-red-700;
  }
  .btn-error-fill {
    @apply bg-white  text-error-500 ring-1 ring-error-500 hover:bg-gray-50;
  }
  .phone-field-input {
    @apply !block !w-full !h-[42px] !rounded-md !border-0 !py-1.5 !pl-12 !pr-20 !text-gray-900 !ring-1 !ring-inset !ring-gray-300 placeholder:!text-gray-300 focus:!ring-inset focus:!ring-indigo-600 sm:!text-sm sm:!leading-6;
  }
  .phone-field-input-error {
    @apply !border !border-red-500 !text-red-900 focus:!ring-red-500;
  }
}
